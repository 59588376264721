import { TimeHelper } from './helpers';

export function EventModel() {
  return {
    id: null, // event id?
    dateFrom: null, // date from  (date object)
    dateTo: null, // date to (date object)
    timeFrom: null, // time from  (XX:YY)
    timeTo: null, // time to    (XX:YY)
    title: null, // title      (string)
    venueType: 'ONSITE', // venue type (ONLINE,ONSITE,HYBRID)
    venue: null, // venue      (object)
    attendanceType: null, // attendance (transmission, sms,free,...)
    image: null, // image data
    imageChanged: false, // if image has been changed by user, reupload if so
    existingImage: false, // if there already is image when editing this event

    // recurrent event options:
    isRecurrent: false, // is event recurrent? (bool)
    firstDate: null, // start date of recurrence
    editRecurrent: false, // if it is a recurrent event being edited
    recurrentPreset: null, // string - name of the frontend preset for recurrent settings
    recurrenceTo: null, // repeat until this date
    recurrentType: null, // (daily,weekly,..)
    recurrentFrequency: null, // once, 2x,...
    recurrentDailyDetails: null, // weekends yes/no (bool)
    recurrentWeeklyDetails: [], // weekdays to be selected (array)
    recurrentMonthlyType: null, // type of monthly recurrence (each|date)
    recurrentMonthlyDates: [], // nth day of month (array)
    recurrentMonthlyEach: [], // nth week of month (array)
    recurrentLastPossibleDate: null, // last date of recurrence (date object)

    timezone: TimeHelper.getDefaultTimezone(), // timezone
    going: false, // is creator going to the event (bool) ?
    tags: [], // selected tags (czech, khaita,...)
    url: null, // url for online events
    password: null, // password for online events
    isOfficial: false, // is official event?
  };
}
