//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SanghaEventDetail from '@/components/SanghaEventDetail.vue';
import { Events } from '@/services/api.service';
import { EventModel } from '@/services/model';
import * as moment from 'moment-timezone';

export default {
  name: 'EventDetail',
  data() {
    return {
      event: new EventModel(),
      eventType: '',
      inProgress: false,
      notFound: false,
    };
  },
  components: {
    SanghaEventDetail,
  },
  methods: {
    loadEvents() {
      this.inProgress = true;
      this.$q.loading.show();
      let id = this.$route.params.id;
      if (!id || id == 0) {
        this.$q.notify({
          type: 'negative',
          message: this.$i18n.t('events.public.error_no_id'),
        });
        this.inProgress = false;
        this.$q.loading.hide();
        return;
      }
      this.notFound = false;
      Events.fetch(id).then(
        (response) => {
          if (!response || !response.data || !response.data.data) return;
          let res = response.data.data.getPublicEvent;
          if (res) {
            this.eventType = 'public';
            this.event = response.data.data.getPublicEvent; //response.data.results[Math.floor(Math.random() * response.data.count)];
            this.event.inProgress = moment().isBetween(
              this.event.date,
              this.event.date_to
            );
            this.event.inPast = moment().diff(this.event.date_to) > 0;
            this.event.inFuture = moment().diff(this.event.date) < 0;
          } else {
            this.notFound = true;
          }

          this.inProgress = false;
          this.$q.loading.hide();
        },
        (errRes) => {
          if (
            errRes.data.errors &&
            errRes.data.errors[0] &&
            errRes.data.errors[0].message &&
            errRes.data.errors[0].message ===
              'Only public event can be returned!'
          ) {
            // use logged Event detail
            this.$store.dispatch('fetchProfile').then(
              () => {
                // fetch authorized event detail
                Events.getSingle(id).then(
                  (response) => {
                    if (!response || !response.data || !response.data.data)
                      return;
                    let res = response.data.data.getEvent;
                    if (res) {
                      this.notFound = false;
                      this.eventType = 'private';
                      this.event = response.data.data.getEvent; //response.data.results[Math.floor(Math.random() * response.data.count)];
                      this.event.inProgress = moment().isBetween(
                        this.event.date,
                        this.event.date_to
                      );
                      this.event.inPast = moment().diff(this.event.date_to) > 0;
                      this.event.inFuture = moment().diff(this.event.date) < 0;
                    } else {
                      this.notFound = true;
                    }
                    this.inProgress = false;
                    this.$q.loading.hide();
                  },
                  () => {
                    this.notFound = true;
                    this.$q.loading.hide();
                    this.inProgress = false;
                  }
                );
              },
              () => {
                this.$q.loading.hide();
                this.inProgress = false;
                // user not logged -> redirect to login
                this.$router.replace({
                  name: 'login',
                  query: { returnTo: this.$route.fullPath },
                });
              }
            );
          } else {
            this.notFound = true;
            this.$q.loading.hide();
            this.inProgress = false;
          }
        }
      );
    },
  },
  computed: {},
  mounted() {
    this.loadEvents();
    // this.$store.dispatch("fetchProfile").then(() => {
    //   });
  },
};
