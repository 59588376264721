//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TimeHelper } from '@/services/helpers.js';
import moment from 'moment-timezone';
import { autoLink } from 'vue-highlights';

export default {
  name: 'SanghaEventDetail',
  props: ['event', 'extendedEvent', 'inModal'],
  methods: {},
  mounted() {},
  computed: {
    descriptionWithLinks() {
      return autoLink(this.extendedEvent.description, {
        extractUrlsWithoutProtocol: true, // Defaults to true
        targetBlank: true, // Defauls to true, applies only in URLs
        // urlClass: 'url-class',
      });
    },
    sameDate() {
      let d1 = moment(this.event.date)
        .tz(TimeHelper.getDefaultTimezone())
        .format('dddd, MMMM Do YYYY');
      let d2 = moment(this.event.dateTo)
        .tz(TimeHelper.getDefaultTimezone())
        .format('dddd, MMMM Do YYYY');
      return d1 == d2;
    },
    languageTags() {
      if (!this.event.tags) return [];
      return this.event.tags.filter(
        (i) => i.category.toLowerCase() === 'language'
      );
    },
    teachingTags() {
      if (!this.event.tags) return [];
      return this.event.tags.filter(
        (i) => i.category.toLowerCase() === 'teaching'
      );
    },
    realUrl() {
      // console.log(this.extendedEvent.url)
      if (!this.extendedEvent.url) return null;
      if (this.extendedEvent.url.substring(0, 4) != 'http') {
        return 'https://' + this.extendedEvent.url;
      } else {
        return this.extendedEvent.url;
      }
    },
  },
};
