var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-uppercase col-1 text-center bg-grey-3 q-pa-sm rounded"},[_c('div',{staticClass:"cell-component month-name"},[_vm._v("\n        "+_vm._s(_vm._f("monthName")(_vm.event.date))+"\n      ")]),_c('div',{staticClass:"cell-component day-number"},[_vm._v(_vm._s(_vm._f("day")(_vm.event.date)))])]),(_vm.event.title)?_c('div',{staticClass:"text-h5 col self-center q-ml-lg text-weight-medium"},[_vm._v("\n      "+_vm._s(_vm.event.title)+"\n    ")]):_vm._e(),(_vm.inModal)?_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"self-start bg-grey-2",staticStyle:{"max-height":"45px","max-width":"45px"},attrs:{"icon":"close","size":"md","round":"","flat":""}}):_vm._e()],1),_c('div',{staticClass:"q-mt-lg"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-5 col-xs-12"},[_c('q-img',{staticClass:"col-8 rounded",staticStyle:{"min-height":"100px"},attrs:{"src":_vm.event.image,"spinner-color":"white","ratio":314 / 167}})],1),_c('div',{staticClass:"col-sm-4 col-xs-12",class:{
          'q-pl-lg q-pr-sm': _vm.$q.screen.gt.xs,
          'q-pt-lg': _vm.$q.screen.lt.sm,
        }},[_c('div',{staticClass:"text-h5 text-bold q-mb-md text-red-6"},[_vm._v("\n          "+_vm._s(_vm.$t('events.detail.date_time'))+"\n        ")]),_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.event.date,'dddd, MMMM Do YYYY')))]),_c('div',{},[_c('span',{staticClass:"text-weight-medium"},[_vm._v(_vm._s(_vm.$t('events.detail.date_start')))]),_vm._v("\n          "+_vm._s(_vm._f("formatDate")(_vm.event.date,'HH:mm z'))+"\n        ")]),_c('div',{},[_c('span',{staticClass:"text-weight-medium"},[_vm._v(_vm._s(_vm.$t('events.detail.date_end')))]),_vm._v("\n          "+_vm._s(_vm._f("formatDate")(_vm.event.dateTo,'HH:mm z'))+"\n        ")]),(!_vm.sameDate)?_c('div',[_vm._v("\n          "+_vm._s(_vm._f("formatDate")(_vm.event.dateTo,'dddd, MMMM Do YYYY'))+"\n        ")]):_vm._e(),_c('div',{staticClass:"q-mt-md"},[(_vm.event.official)?_c('q-badge',{staticClass:"q-py-xs q-px-sm",attrs:{"color":"red-6","text-color":"white"}},[_c('q-icon',{staticClass:"q-mr-xs q-pt-none",attrs:{"name":"verified","color":"white","size":"xs"}}),_vm._v("\n            "+_vm._s(_vm.$t('events.my.event.official'))+"\n          ")],1):_vm._e(),(_vm.event.cancelled)?_c('q-badge',{staticClass:"q-mt-sm xq-pa-md",attrs:{"color":"red","outline":""}},[_c('q-icon',{staticClass:"q-mr-md",attrs:{"name":"cancel","size":"sm"}}),_vm._v("\n            "+_vm._s(_vm.$t('events.detail.cancelled_info')))],1):_vm._e()],1)]),(_vm.event.venue)?_c('div',{staticClass:"col-sm-3 col-xs-12",class:{ 'q-px-sm': _vm.$q.screen.gt.xs, 'q-pt-lg': _vm.$q.screen.lt.sm }},[_c('div',{staticClass:"text-h5 text-bold q-mb-md text-orange-6"},[_vm._v("\n          "+_vm._s(_vm.$t('events.detail.location'))+"\n        ")]),_c('div',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.event.venue.title))]),(!_vm.extendedEvent)?_c('div',[_vm._v(_vm._s(_vm.event.venue.short_location))]):_vm._e(),(_vm.extendedEvent)?_c('div',[(
              _vm.extendedEvent.venue.street &&
              _vm.extendedEvent.venue.street != 'undefined'
            )?_c('div',[_vm._v("\n            "+_vm._s(_vm.extendedEvent.venue.street)+"\n            "+_vm._s(_vm.extendedEvent.venue.house_number)+"\n          ")]):_vm._e(),_c('div',[_vm._v("\n            "+_vm._s(_vm.extendedEvent.venue.zip_code)+" "+_vm._s(_vm.extendedEvent.venue.town)+"\n          ")]),_c('div',[_vm._v(_vm._s(_vm.extendedEvent.venue.country))]),_c('div',[_vm._v(_vm._s(_vm.extendedEvent.venue.contact))]),_c('div',{staticClass:"text-grey"},[_vm._v("\n            "+_vm._s(_vm.extendedEvent.venue.locationLatLon)+"\n          ")])]):_vm._e(),(
            _vm.extendedEvent && (_vm.extendedEvent.password || _vm.extendedEvent.url)
          )?_c('div',{staticClass:"q-mt-xs"},[(_vm.extendedEvent.url)?_c('div',{staticClass:"ellipsis",attrs:{"title":_vm.extendedEvent.url}},[_c('q-icon',{staticClass:"q-mr-xs",attrs:{"name":"link"}}),_c('a',{attrs:{"href":_vm.realUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.extendedEvent.url))])],1):_vm._e(),(_vm.extendedEvent.password)?_c('div',{staticClass:"ellipsis",attrs:{"title":_vm.extendedEvent.password}},[_c('q-icon',{staticClass:"q-mr-xs",attrs:{"name":"lock"}}),_c('i',[_vm._v(_vm._s(_vm.extendedEvent.password))])],1):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-12 q-mt-sm"},_vm._l((_vm.teachingTags),function(tag){return _c('q-badge',{key:tag.id,staticClass:"q-ma-xs"},[_vm._v(_vm._s(tag.name))])}),1),_c('div',{staticClass:"col-12"},_vm._l((_vm.languageTags),function(tag){return _c('q-badge',{key:tag.id,staticClass:"q-ma-xs"},[_vm._v(_vm._s(tag.name))])}),1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 q-mt-lg"},[_c('div',{staticClass:"text-h5 text-bold q-mb-md"},[(_vm.extendedEvent && _vm.extendedEvent.publicShareLink)?_c('a',{attrs:{"href":_vm.extendedEvent.publicShareLink}},[_c('q-icon',{attrs:{"size":"sm","name":"link"}})],1):_vm._e(),(!_vm.extendedEvent || !_vm.extendedEvent.publicShareLink)?_c('router-link',{attrs:{"to":{ name: 'public-event', params: { id: _vm.event.id } }}},[_c('q-icon',{attrs:{"size":"sm","name":"link"}})],1):_vm._e(),_vm._v("\n          "+_vm._s(_vm.$t('events.detail.description'))+"\n        ")],1),(_vm.extendedEvent)?_c('p',{staticStyle:{"white-space":"pre-line","overflow-wrap":"break-word"},domProps:{"innerHTML":_vm._s(_vm.descriptionWithLinks)}}):_vm._e(),(
            (!_vm.extendedEvent || !_vm.extendedEvent.description) &&
            !_vm.event.description
          )?_c('p',{staticClass:"text-grey"},[_c('i',[_vm._v(_vm._s(_vm.$t('events.detail.no_description')))])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }